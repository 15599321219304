import './App.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';


function App() {
  return (
    <Container id='app-container' maxWidth='fixed'>
      <div className='title-text'>Image of some sort</div>
      <div className='title-text'>Title</div>
      <Box className='box-tile'>
        <div className='tile-text'>Game Development</div>
      </Box>
      <Box className='box-tile'>
        3D Modelling
      </Box>
      <Box className='box-tile'>
        Web Applications
      </Box>      
    </Container>
      
  )
}

export default App;
